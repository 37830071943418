import React, { useEffect } from "react";
import ModalMenu from "./ModalMenu";
import { Button } from "react-bootstrap";
import { changeShowAuthMenu } from "./login-action";
import { connect } from "react-redux";
import { initializeAuth } from "./login-action";

const LoginComponent = (props) => {

  const handleShow = () => {
    props.changeShowAuthMenu();
  };

  return (
    <React.Fragment>
      <Button
        className="header-nav-link header-nav-button"
        variant="outline-primary"
        onClick={handleShow}
      >
        Login
      </Button>
      <ModalMenu />
    </React.Fragment>
  );
};

export default connect(null, {
  changeShowAuthMenu,
  initializeAuth,
})(LoginComponent);
